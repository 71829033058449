<template>
    <header class="login">
        <van-nav-bar title="企业注册" left-text="返回" left-arrow @click-left="back"></van-nav-bar>

        <van-form class="login_form" @submit="register">
            <van-field class="form_input" v-model="form.companyName" name="companyName" label="公司名称" placeholder="请输入公司名称" :rules="rules.companyName" />
            <van-field class="form_input" v-model="form.name" name="name" label="姓名" placeholder="请输入姓名" :rules="rules.name" />
            <van-field class="form_input" v-model="form.phone" name="phone" label="手机号" placeholder="请输入手机号" :rules="rules.phone" />
            <van-field class="form_input" v-model="form.vcode" name="vcode" label="验证码" placeholder="请输入验证码" :rules="rules.vcode" >
                <template #button>
                    <van-button size="small" type="primary" @click="sendVcode" :disabled="form.phone=='' || showTimer">发送验证码<span v-if="showTimer">({{countNum}})</span></van-button>
                </template>
            </van-field>
            <van-field class="form_input" v-model="form.password" name="password" label="设置密码" placeholder="请设置密码，不低于6位" :rules="rules.password" />
            <van-field class="form_input" v-model="form.repwd" name="repwd" label="重复密码" placeholder="请重复输入密码" :rules="rules.repwd" />
            <div class="form_tip">手机号为企业负责人登录账号</div>
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit"> 注册 </van-button>
            </div>
        </van-form>
    </header>
</template>

<script>
    import { constants } from "crypto";
    export default {
        data() {
            return {
                showTimer: false,
                countNum: 60,
                form: {
                    companyName: '',
                    name: '',
                    phone: ''
                },
                rules: {
                    companyName: [{ required: true, message: '请输入公司名称' }],
                    name: [{ required: true, message: '请输入姓名' }],
                    phone: [
                        { required: true, message: '请输入手机号' },
                        { validator: this.phoneValidator, message: '手机号格式错误' },
                    ],
                    vcode: [{ required: true, message: '请输入验证码'},],
                    password: [{ required: true, message: '请设置密码，长度不低于6位' },],
                    repwd: [{ required: true, message: '请重复输入密码'},]
                }
            };
        },
        methods: {
            //开始倒计时
            countDown() {
                if (this.countNum > 0) {
                    this.countNum--;
                } else {
                    clearInterval(this.timer);
                    this.showTimer = false;
                }
            },
            sendVcode() {
                if (this.form.phone.length != 11) {
                    this.$toast.fail( '手机号码格式不正确');
                } else {
                    this.$axios.post("/user/sendVcode", {mobile: this.form.phone})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.success('发送成功');
                            this.showTimer = true;
                            this.countNum = 60;
                            this.timer = setInterval(this.countDown, 1000);
                        } else {
                            this.$toast.fail(res.msg);
                        }
                    }).catch(err=> {});
                    
                }
            },
            phoneValidator(val) {
                return /1\d{10}/.test(val);
            },

            register() {
                //检查密码是否一致
                if (this.form.password.length < 6) {
                    this.$toast.fail('密码不能低于6位');
                    return;
                }
                if (this.form.password != this.form.repwd) {
                    this.$toast.fail('两次输入的密码不一致');
                    return;
                }
                
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                
                this.$axios.post("/user/insertCompany", this.form)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('注册成功');
                        this.$router.push("/login");
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            
            back() {
                this.$router.push("/login");
            }
        },
    };
</script>

<style lang="less" scoped>
    .logo {
        font-size: 100px !important;
        margin-bottom: 150px;
    }

    /* 本页公共样式 */
    .login {
        height: 100vh;
        background-color: #fff;
    }

    header {
        text-align: center;
    }

    .login_form {
        padding-top: 20px;
        .form_input {
            margin: 0 0 30px 0;
        }

        .form_btn {
            width: 80%;
            margin: 0 auto !important;
            button {
                background-color: #20a0ff;
            }
        }

        .form_tip {
            margin: 0 0 30px 0;
            font-size: 14px;
            color: #afafaf;
            span {
                color: #ff0000;
            }
        }
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>

